
import auth from "@/modules/auth";
import Vue from "vue";
import { mapState, mapActions } from "vuex";

const emailRegex = new RegExp(
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
);

export default Vue.extend({
  computed: {
    textToPrint() {
      switch (this.status) {
        case "create":
          return "Créer le compte";

        case "forgotten":
          return "Réinitialiser";

        case "login":
          return "S'identifier";

        case "passchange":
          return "Changer le mot de passe";

        default:
          return "S'identifier";
      }
    },
    isPassChange() {
      return !!this.$route.query.token;
    },
  },
  data: () => ({
    email: "",
    password: "",
    repeat: "",
    status: "login",
    valid: false,
    loading: false,
    rules: {
      email: [
        (val: string) =>
          (val && !!val.length) || "Veuillez entrer l'email d'administration",
        (val: string) => emailRegex.test(val) || "Cet email semble invalide",
      ],
      password: (context: any) => [
        (val: string) =>
          context.status === "create" || context.status === "passchange"
            ? (val && val.length > 8) ||
              "Le mot de passe doit contenir minimum 8 caractères"
            : true,
        (val: string) =>
          context.status === "create" || context.status === "passchange"
            ? /[A-Z]/.test(val) ||
              "Le mot de passe doit contenir minimum une majuscule"
            : true,
        (val: string) =>
          context.status === "create" || context.status === "passchange"
            ? /[a-z]/.test(val) ||
              "Le mot de passe doit contenir minimum une minuscule"
            : true,
        (val: string) =>
          context.status === "create" || context.status === "passchange"
            ? /[0-9]/.test(val) ||
              "Le mot de passe doit contenir minimum un chiffre"
            : true,
      ],
      repeatPassword: (context: any) => [
        (val: string) => {
          return (
            val === context.password || "Les mots de passe ne correspondent pas"
          );
        },
      ],
    },
  }),
  methods: {
    ...mapActions(["showAlert"]),
    handleStatus(status: string) {
      switch (status) {
        case "create":
          if (this.status === "login") {
            this.status = "create";
          } else {
            this.status = "login";
          }
          break;
        case "forgotten":
          if (this.status === "forgotten") {
            this.status = "login";
          } else {
            this.status = "forgotten";
          }
          break;
        default:
          this.status = "login";
          break;
      }
    },
    buttonClick() {
      if (this.valid) {
        switch (this.status) {
          case "login":
            this.login();
            break;
          case "create":
            this.create();
            break;
          case "forgotten":
            this.forgotten();
            break;
          case "passchange":
            this.passChange();
            break;
          default:
            break;
        }
      }
    },
    async login() {
      try {
        this.loading = true;
        const route = `${process.env.VUE_APP_API_HOST}/admin/login`;
        const headers = new Headers();
        let loginObject = {} as Record<string, any>;
        let exp: number | boolean = 0;
        headers.set("Content-Type", "application/json");
        const response = await fetch(route, {
          method: "POST",
          headers,
          body: JSON.stringify({
            email: this.email,
            password: this.password,
          }),
        });
        switch (response.status) {
          case 200:
            loginObject = await response.json();
            // We've got the login object, we can store it
            // First we get the exp timestamp
            exp = auth.tokenStillValid(loginObject.token);
            if (exp) {
              auth.login({
                userId: loginObject.userId,
                token: loginObject.token,
                expires: exp as number,
              });
              this.$router.push("/");
            } else {
              this.showAlert({
                message: "Erreur serveur",
                color: "error",
                timeout: 4000,
              });
            }
            break;
          case 404:
            this.showAlert({
              message: "Mauvais e-mail ou mot de passe",
              color: "warning",
              timeout: 4000,
            });
            break;
          default:
            this.showAlert({
              message: "Erreur serveur",
              color: "error",
              timeout: 4000,
            });
            break;
        }
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.loading = false;
      }
    },
    async create() {
      try {
        this.loading = true;
        const route = `${process.env.VUE_APP_API_HOST}/admin/firstadmin`;
        const headers = new Headers();
        headers.set("Content-Type", "application/json");
        const response = await fetch(route, {
          method: "POST",
          headers,
          body: JSON.stringify({
            email: this.email,
            password: this.password,
          }),
        });
        switch (response.status) {
          case 201:
            this.showAlert({
              message: "L'administrateur a été configuré!",
              color: "success",
              timeout: 2500,
            });
            setTimeout(() => {
              this.email = "";
              this.password = "";
              this.repeat = "";
              (this.$refs.loginForm as HTMLFormElement).reset();
              (
                this.$refs.loginForm as HTMLFormElement & {
                  resetValidation: () => void;
                }
              ).resetValidation();
              this.status = "login";
            });
            break;
          case 403:
            this.showAlert({
              message: "Un admin a déjà été configuré!",
              color: "error",
              timeout: 4000,
            });
            break;
          default:
            this.showAlert({
              message: "Erreur serveur",
              color: "error",
              timeout: 4000,
            });
            break;
        }
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.loading = false;
      }
    },
    async forgotten() {
      try {
        this.loading = true;
        const route = `${process.env.VUE_APP_API_HOST}/admin/${this.email}/newpass`;
        const response = await fetch(route);

        switch (response.status) {
          case 200:
            this.showAlert({
              message:
                "Si cet email est lié à un administrateur, un message de réinitialisation vient d'être envoyé",
              color: "success",
              timeout: 4000,
            });
            break;
          default:
            this.showAlert({
              message: "Erreur serveur",
              color: "error",
              timeout: 4000,
            });
        }

        this.loading = false;
      } catch (error) {
        console.error(error);
        this.loading = false;
      }
    },
    async passChange() {
      try {
        this.loading = true;
        const token = this.$route.query.token;

        const route = `${process.env.VUE_APP_API_HOST}/admin/changepassword`;
        const headers = new Headers();
        headers.set("Content-Type", "application/json");
        headers.set("Authorization", `Bearer ${token}`);

        const response = await fetch(route, {
          method: "PUT",
          headers,
          body: JSON.stringify({ password: this.password }),
        });
        switch (response.status) {
          case 500:
            this.showAlert({
              message:
                "Impossible de changer le mot de passe. Le lien par mail est uniquement valable 15 minutes, veuillez réessayer",
              color: "warning",
              timeout: 4000,
            });
            break;
          case 200:
            this.showAlert({
              message:
                "Le mot de passe a correctement été changé, veuillez vous identifier de nouveau",
              color: "success",
              timeout: 4000,
            });
            setTimeout(() => {
              this.status = "login";
              this.$router.push("/login");
            }, 3000);
        }
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.loading = false;
      }
    },
  },
  watch: {
    isPassChange() {
      this.status = this.isPassChange ? "passchange" : "login";
    },
  },
  beforeMount() {
    this.status = this.isPassChange ? "passchange" : "login";
  },
});
